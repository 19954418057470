.carouselExampleSlidesOnly{
    position: absolute;
    z-index:1;
}

#imagem-slide{
    filter: brightness(60%); 
}
@font-face{
    font-family: CoHeadlineBold;
    src: url("../fonts/CoHeadlineBold.ttf");
}
.seta{
    margin-left: 90%;
    margin-top: 30%;
    z-index: 9;
    position: absolute;
    
}
.conhecaMais{
    color: #441c5c;
    text-align: center;
}
#imgSeta{
    color: #441c5c;
    font-size: 600%;
    text-align: center;
}
img{
    max-width:100%;
    height:auto;
    color: red;
    text-align: center;
    
}
.carousel-caption{
    width: 400px;
    z-index: 0;
    height: 500px;
    font-family: CoHeadlineBold;
    overflow: hidden;
    font-size: 1em;
    padding-top: 30px;
    text-align: justify;
    padding-bottom: 40px;
}
h1{
    font-size: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
    text-align: center;
    align-self: multiplos;
}
#im{
    margin-top: 1%;
    position: absolute;
    padding-bottom: 0px;
    z-index:2;
   
}
