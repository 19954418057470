.TituloDepoimentos{
    padding-left: 2%;
    padding-top: 5%;
    font-size: 5vh;
    color: #eb7228;
}
.divDepoimentos{
    width: 100%;
    height: 800px;
 
    margin-bottom: 80px;
    background-size: cover;
    background-position: center;
    background-image: url("../../imagens/backgroundDepoimentos.png");
}
@media(min-width:900px) and (max-width:1030px)
{
    .divDepoimentos{
        margin-bottom: 200px;
    }
}
.divSlider{

    position: absolute;
    margin-left: 10%;
    margin-right: 10%;
    align-self: center;
    justify-self: center;
    width: 80%;
    height: 500px;
}
@media(max-width:480px)
{
    .divDepoimentos{
        margin-bottom: 15px;
    }
    .divSlider{
        width: 100%;
        margin-left: 0%;
        margin-top: 0px;
        height: 419px;
    }
}
.seta{
    color: red;
}