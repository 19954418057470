
.page-footer{
    margin-top: -9%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    background-image: url('../../imagens/footer.png');
    background-repeat: no-repeat;
    background-size:cover;
    color: white; 
    position: absolute;
   
}

.barrinha{
    background-color: white;
    
}
.d-flex{
    font-size:12px ;
}
p{
    margin-bottom: 5px;
}

.logo, .intro, .end{
    padding-top: 15%;
}
.logo{
    text-align: center;
}
@media(max-width:700px)
{
    .logo-taua-footer, .logo-footer{
        display:flex;
        flex-direction: row;
        width: 50%;
        height: auto;
        text-align: center;
        justify-content: center;
    }
}
@media(min-width:740px)
{
    .logo-taua-footer, .logo-footer{
        width: 80%;
        height: auto;
        text-align: center;
        justify-content: center;
    }
}

a{ 
    color: inherit; 
}
.linkTaua{
    
    color: white;
}
