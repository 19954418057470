.DivSize{

    background-size: cover;
    object-fit: cover;
}
img{
    object-fit: cover;
}

.roundedBorders{
    
    border-radius: 20px;
}
.carousel *{
    border-radius: 20px;
}
.carousel .carousel-status {
   
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 0px;
    text-shadow: 1px 1px 1px rgba(0,0,0,.9);
    color: #fff;
}
.imgSize{
    height: 800px;
    width: 800px;
}
.carouselRCI{
    position: absolute;
    margin-bottom: 1000px;
}