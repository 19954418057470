@font-face{
    font-family: CoHeadlineBold;
    src: url("../fonts/CoHeadlineBold.ttf")
}
@font-face{
    font-family: CoHeadlineLight;
    src: url("../fonts/CoHeadlineLight.ttf")
}
@font-face{
    font-family: CoHeadlineRegular;
    src: url("../fonts/CoHeadlineRegular.ttf")
}
@font-face{
    font-family: KrubBold;
    src: url("../fonts/KrubBold.ttf")
}
@font-face{
    font-family: KrubItalic;
    src: url("../fonts/KrubItalic.ttf")
}
@font-face{
    font-family: KrubRegular;
    src: url("../fonts/KrubRegular.ttf")
}
