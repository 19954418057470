.DialogCalc{
    padding-left: 100%;
    
}
.botoes{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
}
Dialog{
    padding-left: 100%;
}

.botaoCalc{
    font-family: CoHeadlineBold;
    background-color: #5b267b;
    color: white;
    width: 44%;
    height: 40px;
    font-size: 1.5rem;
    border-radius: 4px;  
}

