.App{
  margin-right: 0px;
}

.bgApp
{
    background-color: white;
}
.oXBlock{
  overflow-x: hidden;
}
  