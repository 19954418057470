#promocoes{
   
    margin-top: 100px;
    position: relative;

}
#btnpromo{
 text-align: center;
}


.card-body{
    text-align: center;
    height: 140px;
    color: white;
    border-radius: 0px 0px 15px 15px;
}
.border{
    border-radius: 20px;
}
.widthCard{
    width: 90%;
}
.roundedButton{
    width: 90%;
    border-radius: 25px;
}
.fontStyle{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.whiteSmoke{
    background-color: whitesmoke;
}
.card{
    background-color: whiteSmoke;
}
img{
    object-fit: cover;
}
.link-promo{
  
   color: white;
}
a { color: inherit; } 

@media(min-width:1300px)
{
    .tituloPromo{
        margin-left: 180px;
    }
}
.tituloPromo{
   
    color: #eb7228;
    font-size: 2.4em;
    font-family: CoHeadlineBold;
}
@media(max-width:480px)
{
    .tituloPromo{      
        margin-left: 8%;
    }
}
@media(max-width:900px)
{
    .margimTopAlta{      
    }
}
