.paragrafoDep{
    text-align: justify;
    margin-top: 15%;
    font-size: 23px;
    font-family: KrubRegular;
}

#imgDepo{
    object-fit: cover;
}
@media(min-width:760px) and (max-width:850px)
{
    #imgDepo{
        object-fit: cover;
        height: 56vh;
    }
}
@media(min-width:960px) and (max-width:1150px)
{
    #imgDepo{
        height: 40vh;
    }
}
@media(max-width:480px)
{
    .paragrafoDep
    {
        text-align: justify;
        font-family: KrubRegular;
        margin:5%;
        font-size: 18px;
        text-align: justify;
    }
}
#imgDepo{
    object-fit: cover;
}
.objectCover{
    object-fit: cover;
    background-size: cover;
}
.imgSlide{
    background-size: cover;

}
.bgSlide{
    background-image: url("../../imagens/imgDep1.png");
    background-size: cover;
    height: 500px;
    width: 100%;
}
.prev{
    color: aqua;
}
.colorSeta{
    color: red
}
