.menu-fixo {
	position: fixed;
    top: 0;
    width: 100%;
	z-index: 99;
    transition: all .5s;
    background-color: #441c5c;
    height: 80px;
}

.log{
    background-color: white;
    width: 30%;
    border-bottom-right-radius: 100%;
    
}

.log-fixo{
    background-color:transparent;
    border-bottom-right-radius: 0%;
    width: 20%;
    margin-left: 20px;
    margin-right: 40px;
}
@media(max-width:1199px){
    
     
    #navbar-nav{
        widows: 100%;
        background-color: transparent;
    }
    .navbar-nav{
        position: absolute;
        top: 0%;
        z-index: 59;
        background-color: #441c5c;
        width: 100%;
        align-items: center;
        text-align: center;
    }
    #navbar-ul{
        padding-top: 15%;
        width: 100%;
    }
   
    #vip{
        border-color: white;     
    }
    
    .it{
        margin-left: -5%;
        color: white;
        border-bottom: 2px solid #441c5c;
        width: 100%;
        align-items: center;
    }
    #wha, #vi{
        background-color: #441c5c;
        align-items: center;
    }
    
    .it:hover{
        background-color: #441c5c;
    }
}
a {
    text-decoration: none;
    color: #fff;
    display: block;
    padding: .8em 0;
   
  }
a:last-child {
    border-bottom: 0;
}
.navbar-nav{
    display: flex;
    justify-content: space-between;

}
nav{
    display: flex;
    justify-content: space-between;
    margin-right: 0px;
    padding-right: 0px;

}
#geral{
    position: absolute;
    z-index:10;
    height: 20%;
}

#vip{
    width: 120px;
    height: 45px;
    margin-top: 10px;
    background-color: #441c5c;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    color: white;  
    margin-bottom: 10px;   
}
#vip:hover{
    width: 120px;
    height: 45px;
    background-color: white;
    color: #441c5c;
}
.vip-fixo{
    width: 120px;
    height: 45px;
    background-color: #441c5c;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-color: white;
    border-width: 2px;
    color: white;  
    margin-bottom: 10px;
}

@font-face{
    font-family: CoHeadlineBold;
    src: url("../fonts/CoHeadlineBold.ttf");
}
.collapse{
  
    font-size: 16px;
    font-family: CoHeadlineBold;
    font-weight: 700;
    margin-top: 20px;
  
    align-self: flex-start; 
    width: 100%;
    display: flex;
    justify-content: space-between;
    
}

.navbar-toggler{
    margin-top: 10px;
  
    display: flex;
    align-items: flex-start;
    align-self: flex-start;

}
.mn{
    margin-top: -0.8% !important; 
}
.it{
    margin-top: -20px;
    color: white;
    margin-left: 20px;    
}
.it:hover{
    font-size: 20px;
}
a { color: inherit; }

a:link{
    text-decoration:none;
}
a.tex:hover {
    color:white;
}
.whats{
    font-size: 30px;
    color: #34af23;
}
.whats:hover{
    font-size: 50px;
}

.tex{
    
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-transform: uppercase;
    
}
.navbar-toggler{
    z-index: 60;

}
@font-face{
    font-family: CoHeadlineBold;
    src: url("../fonts/CoHeadlineBold.ttf");
}
.titleLogin, .login_style, .senha_login{
    font-family: CoHeadlineBold;
    color: white;
    justify-content:center;
}
#login_style, #senha_login{
    width: 100%;
}
.titleLogin, .login_style, .senha_login{
    font-family: CoHeadlineBold;
    justify-content:center;
    width: 100%;
}
.diologLogin{
    padding-left: 100%;
    background-color: #5b267b;
}
.botoes{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
}
Dialog{
    padding-left: 100%;
}
.botaoLogin{
    font-family: CoHeadlineBold;
    color: #5b267b;
    width: 44%;
    height: 40px;
    font-size: 1.5rem;
    border-radius: 4px;
    
}
.botaosenha{
    font-family: CoHeadlineBold;
    color: white;
    background-color: transparent;
    font-size: 1rem;
    border-radius: 4px;
    border: none ;
}
#lin{

    margin-top: -10px;
    color: #5b267b;
}
#imgLogin{
    width: 60%;
  


}
.loginImg{
    display: flex;
    align-content: center;
    align-self: center;
    align-items: center;
    justify-content: center;
    justify-self: center;
    justify-items: center;
}


