.sizeImg{
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.divInsta{
    object-fit: contain;
    background-size: contain;
    margin-top: 5%;
    border-radius: 15px;
    width: 500px;
    height: 50vh;
    
    text-align: center;
    text-justify: center;
    justify-self: center;
    justify-content: center;
   
    
    
}
.static{
    padding-bottom: 5%;
    position: static;
    text-align: center;
    text-justify: center;
    justify-self: center;
    justify-content: center;
    
}
@media(min-width:600px)
{
    .sizeImg{
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.imagemInsta{
    object-fit: contain;
    width: 100%;
    height: 400px;
    margin-bottom: 100px;

    background-size: contain;
}
@media(max-width:480px)
{
    .sizeImg {
        overflow: hidden;
      
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.pd{
    padding-left: 0px;
}