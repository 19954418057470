.containerBgImage{
    position: relative;
    background-position: center;
    background-size: cover;
    background-color: white;
}
.static{
    position: static;
}
.mt{
    padding-top: -10%;    
}
.barraEsquerda{
    height: 1000px;
    background-image: url("../../imagens/barraEsquerdaContato.png");
    background-size: cover;
}
@media(max-width:480px)
{
    .barraEsquerda{
        margin-top: 55vmin;
        height: 183vmin;
        background-image: url("../../imagens/barraEsquerdaContato.png");
        background-size: cover;
    }
    .divBordaDireitaZ{
        margin-top: 55vmin;
        height: 183vmin;
        background-position: right;
        background-image: url("../../imagens/barraDireitaContato.png"); 
        background-size: cover;
    }
}
.barraDireita{
    position: relative;
    background-position: right;
    background-image: url("../../imagens/barraDireitaContato.png"); 
    height:auto;
    background-size: cover;
}
@media(min-width:700px) 
{
    .divBordaDireitaZ{
        position: relative;
        background-position: right;
        background-image: url("../../imagens/barraDireitaContato.png"); 
        height:1000px;
        background-size: cover;
    }
}
