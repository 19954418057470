

.TituloCompartilhado{
    font-family: CoHeadlineBold;

    font-size: 2rem;
    font-weight: bold;

    color: #eb7228;
   
}

h1{
    font-family: CoHeadlineBold;
}
#tempoCompIm{
    border-radius: 50%;
}
@media(min-width:1200px) and (max-width:1799px)
{ 
    .divBordaDireita{
       
        background-image: url("../../imagens/BarraDireitaCorrigida.png"); 
        height:1000px;
        background-size: cover;
        position: absolute;
        background-position: right;
    }
}

@media (min-width:1000px) and (max-width : 1199px)
{
    
    .divBordaDireita{
        
        background-image: url("../../imagens/BarraDireitaCorrigida.png"); 
        height: 800px;
        background-size: cover;
        position: absolute;
        background-position: right;
    }
}
@media(min-width:1800px)
{
    .divBordaDireita{
        background-image: url("../../imagens/BarraDireitaCorrigida.png"); 
        height:1000px;
        background-size: cover;
        position: absolute;
        background-position: right;
    } 
}
@media(min-width:900px) and (max-width:999px)
{ 
    
    .divBordaDireita{
        position: absolute;
        background-position: right;
        background-image: url("../../imagens/BarraDireitaCorrigida.png"); 
        height:720px;
        background-size: cover;
    }
   .divTextoCorrido{ 
        width: 100%;
        margin-right: 10%;
        margin-left: 20%;
    }
}

@media (min-width:300px) and (max-width:480px)
{
    .TituloCompartilhado
    {
        font-size: 1.6rem;
    }
    
    .divTextoCorridoTempo{
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
      
    }
    .textoParagrafo {
        font-size: 1.2em;
       
        text-align: justify;
       
    }
    .divBordaDireita{
        margin-top: -10vh;
        position: absolute;
        background-position: right;
        background-image: url("../../imagens/BarraDireitaCorrigida.png"); 
        height:300px;
        background-size: cover;
    }
}
@media  (max-width:299px)
{
    divBordaDireita{
        margin-top: -10vh;
        position: absolute;
        background-position: right;
        background-image: url("../../imagens/BarraDireitaCorrigida.png"); 
        height:100%;
        background-size: cover;
    }
}

.divTextoCorrido
{
    margin-left: 5vw;
    margin-right: 2vw;
    background-size: cover;
   
    
    
} 
  div.noPadding {
    padding-left: 0;
    padding-right: 0;
}

div.noMargin {
    margin-left: 0;
    margin-right: 0;
}
@media(max-width:899px)
{
    .divBordaDireita{
        position: absolute;
        background-position: right;
        background-image: url("../../imagens/BarraDireitaCorrigida.png"); 
        height:720px;
        background-size: cover;
    }
    
}

.bgWhiteSmoke{
    background-color: whitesmoke;
}
@font-face{
    font-family: CoHeadlineBold;
    src: url("../fonts/CoHeadlineBold.ttf")
}