
.relative{
    position: relative;
    z-index: 2;
}

.bRadius{
    border-radius: 15px;
}
.card{
 
    margin: 5%;
}
.cards-body{
    border-radius: 0px 0px 15px 15px;
    height: 100px;
    color: white;
}
#card1, #card5,#card9{
    background-color: #441c5c;
}
#card2, #card6, #card10{
    background-color: #f9c200;
}
#card3, #card7{
    background-color: #eb7228;
}
#card4, #card8{
    background-color: #005a3a;
}
.card-img-top{
    height: 300px;
    object-fit: cover;
    border-radius: 15px 15px 0px 0px;
    background-size: cover;

    width: auto;
}
.card-deck{
    margin-right: 0px;
    margin-left: 0px;
    
}
.titulo{
    color:#eb7228;
    padding-left: 30px;
    font-size: 50px;
  
}
.overlay{
    position: absolute;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
}
#overlay1, #overlay5, #overlay9{
    background-color: #441c5c;
}
#overlay2, #overlay6, #overlay10{
    background-color:  #f9c200;
}
#overlay3, #overlay7{
    background-color:  #eb7228;
}
#overlay4, #overlay8{
    background-color:   #005a3a;
}
.card:hover .overlay{
    height: 100%;
}
.texto{
    margin-top: 50%;
    justify-self: center;
    align-self: center;
    justify-items: center;
    align-items: center;
    font-family: KrubRegular;
    color: white;
    font-size: 15px;
    position: absolute;
    text-align: center;
}
.mtAlto{
    margin-top: 10%;
}
.mt8{
    margin-top: 8%;
}
.card-title{
    align-self: center;
    text-align: center;
}
.img{
    object-fit: cover;
}
img{
    object-fit: cover;
}
.cWidth
{
    object-fit: cover;
    border-radius: 20px;
    width: 85%;
}
.shadow {
    border: 1px solid;
    padding: 10px;
    box-shadow: 5px 10px 8px #888888;
  }
  .whiteSmoke{
      background-color: whitesmoke;
  }
.bgImg{
    height: 300px;
    background-image: url('../../imagens/card2.jpg');
    background-size: cover;
}
.fontTitulo{
    font-family: CoHeadlineBold;
}
@media(max-width:480px)
{   .tituloBeneficios
    {
        margin-left:10px;
    }
}
@media(min-width:1000px)
{
    .tituloBeneficios{
        margin-left: 3.8em;
      
    }
    
}
.tituloBeneficios{

    font-family: CoHeadlineBold;
    color:#eb7228;
    padding-left: 30px;
    font-size: 2.7em;
  
}
