.marginTops{
    
}
.relativeGrid{
    padding-left: 0;
    position: relative;
}
.static{

    padding-left: 0px;
    position: static;
}
.imagemInsta{
    width: 100%;
    height: 400px;
    margin-bottom: 100px;
    background-color: blue;
    background-size: cover;
}
.tituloEspecial{
    padding-bottom: 0% !important;
    color: #212529;
    font-size: 28px;
    font-family: CoHeadlineBold;
}
@media(max-width:480px)
{
    
    .linkInsta{
        margin-bottom: -10% !important;
        margin-top: -10% !important  ;
        padding-bottom: 0% !important;
        padding-top: 0% !important;
        color: #eb7228;
    }
    .imagemInsta{
        margin-bottom : 20px;
    }
}
.spamEspecial{
    text-transform: uppercase;
    color: #eb7228;
    font-family: CoHeadlineBold;
    font-size: 50px;
    font-weight: bolder;
}
@media(min-width:700px)
{
    .
    .linkInsta{
        margin-bottom: -3.4% !important;
        margin-top: -3.4% !important  ;
        padding-bottom: 0% !important;
        padding-top: 0% !important;
        color: #eb7228;
    }
}
.linkInsta:hover{
    color : #F6A91E;
}
@media(max-width:600px)
{
    .marginTopMin{
        margin-top: -37%;
    }
}
@media(min-width:700px)
{
    .marginTopMin{
        margin-top: 30%;
    }
}
@media(min-width:800px)
{
    .marginTopMin{
        margin-top: -13%;
    }
}
.marginBottomMedium{
    margin-bottom: 5%;
}
