
.margem{
    margin-top: 0px;
}

body, html {
    height: 100%;
    }
.alignCenter{
      font-size: 80px;
     text-align: center;
  }
.fontTitulo{
    font-family: CoHeadlineBold;
}
.tiraMargem{

    padding-left: 0px !important;
    padding-right: 0px !important;
}

a:not([href]) {
    color: white;
    text-decoration: none;
}
@media(max-width:1000px)
{
    .ButtonTeste{
        margin-left: 7%;
        width: 8rem;
        margin-top: 5%;
        font-weight: bold;
        background-color:#5b267b;
        border-radius:15px;
        display:inline-block;
        cursor:pointer;
        font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size:20px;
        padding:10px 17px;
        text-decoration:none;
    }
    .Titulo{
   
        padding-bottom: 1rem;
        font-size: 1.5rem;
        color : #5b267b;
        font-family: CoHeadlineBold;
    
    }

}
@media(min-width:1001px)
{
    .ButtonTeste {
        margin-left: 2%;
        width: 8rem;
        margin-top: 5%;
        font-weight: bold;
        background-color:#5b267b;
        border-radius:15px;
        display:inline-block;
        cursor:pointer;
        font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size:25px;
        padding:10px 17px;
        text-decoration:none;
    
    
    }
    .Titulo{
   
        padding-bottom: 1rem;
        font-size: 3rem;     
        color : #5b267b;
        font-family: CoHeadlineBold;
    
    }
}

.myButton:hover {
    text-decoration: none;
    color: white;
    background-color: #923EC7;

}
.Titulo{
   
    padding-bottom: 3rem;
    
    color : #5b267b;
    font-family: CoHeadlineBold;

}
.styleTextArea{
    width : 60%;
    height : 12rem;
    borderRadius : 15px;
}