
.sizeImagem{
    overflow: block;
    width: 100%;
    height: 100%;
}
.oBlock{
    overflow: hidden;
}
.marginBaixo
{
    margin-bottom: 200px;
}
@font-face{
    font-family: CoHeadlineBold;
    src: url("../fonts/CoHeadlineBold.ttf");
}
.TituloAbout
{
   
    overflow: hidden;
    margin-top: 100px;
    font-weight: bold;
    font-size: 2rem;
    font-family: CoHeadlineBold;
    text-align: start;
    text-transform: uppercase;
    color: #5b267b;
}
.textoParagrafo{
    font-family:KrubRegular;
    overflow: hidden;
    font-size: 1.3em;
    padding-top: 30px;
    text-align: justify;
    padding-bottom: 40px;
}

@media (max-width: 480px) {
    .content{
    
        overflow: hidden;
        margin-left: 10px;
        padding-right: 10px;
        margin-right: 10px;
    }
}
@media(max-widht:1400px)
{
    
}
@media(max-width : 480px){
    .img-circular
    {
        overflow : block;
        margin-left: -20px;
    }
}
.img-circular{
    overflow: block;
    margin-left: 200px;
    width: 400px;
    height: 600px;
    background-image: url('../../imagens/o que é o tvc.jpg');
    background-size: cover;
    display: block;
    border-top-left-radius: 100px;
    -webkit-border-top-left-radius: 100px;
    -moz-border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    -webkit-border-bottom-right-radius: 100px;
    -moz-border-bottom-right-radius: 100px;
   }
.borderRadius{
    border-radius: 80%;
}
.myButton {
    
    margin-top: 5%;
    font-weight: bold;
	background-color:#5b267b;
	border-radius:16px;
	display:inline-block;
	cursor:pointer;
	color:whitesmoke;
	font-family:CoHeadlineRegular;
	font-size:25px;
    padding:10px 17px;
	text-decoration:none;

}
a{
    color: white;
}
.myButton:hover {
    text-decoration: none;
    color: white;
    background-color: #923EC7;

}
.myButton:active {
    color: white;
	position:relative;
	top:1px;
}
.circle{
    background-image:url("../../imagens/family2.jpg");
    width:50%;
    border-radius:50%;
    padding-left: 50px;
    background-size: cover;
    height: 600px;
    background-color: palegoldenrod;
    
  }
  
  .circle{
    
    content:"";
    padding-bottom:100%;
    display:block;
}
.bgTeste{
    background-size: cover;
    width: 500px;
    height: 400px;
    border-radius: 25%;
    background-color: blueviolet;
}
div.noPadding {
    padding-left: 0;
    padding-right: 0;
}

div.noMargin {
    margin-left: 0;
    margin-right: 0;
}
.ovHidden{
    overflow: hidden;
}
.posRelative{
    position: relative;
}
.textoTitulo{
   width: 80%;
    height: 600px;
    background:blue;
}

@media(min-width:700px)
{
    .divTextoAbout{
     
        margin: 5%;
        padding: 0px;
        background-size: cover;
    }
    .divCirculo{
        float: right;
        right:50vw;
        background-position: right;
        width: 500px;
        background-image: url("../../imagens/o que é o tvc.jpg");
        border-radius:50%;
        background-size: cover;
        height: 500px;
        background-color: blue;
    }   
}
@media(max-width:700px)
{ 
    .divCirculo{
        float : right;
        right: 100vh;
        background-position: right;
        width: 40rem;
        background-image: url("../../imagens/o que é o tvc.jpg");
        border-radius:50%;
        background-size: cover;
        height: 40rem;
   
    }
}
@media(min-width:950px) and (max-width:1190px)
{ 
    .divCirculo{
        float : right;
        right: 15vh;
        background-position: right;
        width: 40rem;
        background-image: url("../../imagens/o que é o tvc.jpg");
        border-radius:50%;
        background-size: cover;
        height: 40rem;
   
    }
}
@media(min-width:948px) and (max-width:992px)
{
    .divCirculo{
        float : right;
        right: 50vh;
        background-position: right;
        width: 40rem;
        background-image: url("../../imagens/o que é o tvc.jpg");
        border-radius:50%;
        background-size: cover;
        height: 40rem;
    }
   
}
@media(max-width:480px)
{   
    .TituloAbout{
        font-size: 2rem;
        text-align: center;
        padding-top: 20px;
    }
    .divTextoAbout{
        margin: 7%;
        margin-top: -15vh;
        padding: 15px;
        background-size: cover;
    }
    .divCirculo{
        margin-top: 2vh;
        right: 105vw;    
        background-position: right;
        width: 370px;
        background-image: url("../../imagens/o que é o tvc.jpg");
        border-radius:50%;
        padding-left: 100px;
        background-size: cover;
        height: 370px;

    }
}
@media(max-width:350px){
    .divCirculo{
        margin-top: 2vh;
        right: 90vw;    
        background-position: right;
        width: 300px;
        background-image: url("../../imagens/o que é o tvc.jpg");
        border-radius:50%;
        padding-left: 50px;
        background-size: cover;
        height: 300px;
        background-color: blue;
    }
}
@media(min-width:1200px)
{

    .TituloAbout{
        font-size:2rem;
    }
    .divTextoAbout{
        margin-top: 100px;
        background-size: cover;
    }
    
    .divCirculo{

        float : right;
        right: -5vw;
        background-position: right;
        width: 40vw;
        background-image: url("../../imagens/o que é o tvc.jpg");
        border-radius:50%;
        background-size: cover;
        height: 40vw;
   
    }
}
@media(min-width:650px)
{
  
    .divCirculo{
        float: right;
        
    }
    
}
.bgWhiteSmoke
{
    background-color: #fafafa;
}
.marginTopz{
    margin-top: 0px;
}