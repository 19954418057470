
.sizeImg{
 
    width: 100%;
    height: 100%;
}
img{
    object-fit: cover;
}
.padTop{   
    padding-left: 0px;
    padding-right: 40px;
}
@font-face{
    font-family: CoHeadlineBold;
    src: url("../fonts/CoHeadlineBold.ttf");
}
.tituloRci{
    font-family: CoHeadlineBold;
    font-size: 50px;
    
    color: #5b267b;
    text-align: center;
}
.textoCorrido{
    font-family: KrubRegular;
    font-size: 1.3em;
    text-align: justify;
    padding-bottom: 50px;
}
.styleButton{
    color: white;
    text-align: center;
    background-color: #5b267b;
    align-self: center;
    margin-bottom: 20px;
}
body, html {
    height: 100%;
}

.buttonStyl{
    color: #5b267b;
    background-color: #5b267b;
}
.container-imagem{
    width: 300px;
    height: auto;
}

.backImage{

    background-size: cover;
    width: 250px;
    height: 800px;
}
.backBorda{

    background-image: url("../../imagens/BarraRciPng.png");
    height: 1000px;
    background-size: cover;
    
}
.divBorda{
       
    background-size: cover;
    position: absolute;
    background-image: url("../../imagens/BarraRciPng.png");
    height: 800vh;
    background-position: left;
}



@media (min-width:350px) and (max-width:899px)
{
   
    
    #rcis{
        padding-right: 20%;
        margin-left: 20px;
        margin-right: 20px;
        width: 90%;
        margin-bottom: 200%;
    }
    .divBorda{
       
        background-size: cover;
        position: absolute;
        background-image: url("../../imagens/BarraRciPng.png");
        height: 800vh;
        background-position: left;
        
    }

}
/* @media(min-width:900px)
{
    .divTexto{
       
      
    }
    .rcorners {
        margin-left: 1.6vw;
        margin-bottom: 400px;
        width: 40vw;
        height: 600px !important;
        border-radius: 25px;
        padding: 20px;
        background-size: cover;
      
    }
    .divBorda{
       
        background-size: cover;
        position: absolute;
        background-image: url("../../imagens/BarraRciPng.png");
        height: 140vh;
        background-position: left;
    }
    #rcis{
        margin-bottom: 100px;
      
    }


} */
/* @media(max-width:575px)
{
    .textoCorrido{
        font-family: KrubRegular;
     
        font-size: 1.2em;
        text-align: justify;
       
        padding-right: 20px;
    }
    .divTexto{
        position: absolute;
        margin-right: 20px;
        padding: 0px;
        background-size: cover;
     
    }
    .rcorners {
        
        padding: 2%;
        float: right;
        width: 100%;
        height: 500px;
        border-radius: 25px;
      
        background-size: cover;
        
    
      }
      .divBorda{
        position: absolute;
        background-image: url("../../imagens/BarraRciPng.png");
        height: 720px;
        object-fit: cover;
        background-size: cover;
        background-position: left;
    }
    .tituloRci{
        font-size: 2rem;
        
        padding-left: 31%;
        padding-right: 25%;
        text-align: center;
    }
    #rcis{
        
       
    }

} */
@media(min-width:1200px) and (max-width:1600px)
{
    #rcis{
        margin-bottom: 50%;   
    }
    .divBorda{
     
        background-image: url("../../imagens/BarraRciPng.png");
        height:1000px;
        background-size: cover;
        position: absolute;
        background-position: left;
    }

}
@media(min-width:1601px) 
{
    #rcis{
        margin-bottom: 50%;   
    }
    .divBorda{
     
        background-image: url("../../imagens/BarraRciPng.png");
        height:1000px;
        background-size: cover;
        position: absolute;
        background-position: left;
    }
}
@media(min-width:900px) and (max-width:1199px)
{
   
    .divTexto{
        
        margin-left: -60px;
        margin-right: 60px;
        padding: 0px;
        background-size: cover;
    }
    .divBorda{
     
        background-image: url("../../imagens/BarraRciPng.png");
        height: 100%;
        position: absolute;
        background-size: cover;
        background-position: left;
    }   
    .rcorners {
   
        
        margin-right: 20px;
        color: chocolate;
        width:50%;
        
        /*background-image: url("../../imagens/castelo.jpg"); */   
        border-radius: 25px;
        padding: 20px;
        background-size: cover;
      }
      #rcis{
        margin-bottom: 170%;
       
    }

}

  .hidden{
      overflow: hidden;
  }
@media(max-width: 349px){
    .rcorners {
        width: 80%;
    }
    #rcis{
        
        padding-right: 10%;
        margin-left: 5px;
        margin-right: 10px;
        width: 90%;
        margin-bottom: 700px;
        
    }
    .divBorda{
       
        background-size: cover;
        position: absolute;
        background-image: url("../../imagens/BarraRciPng.png");
        height: 800vh;
        background-position: left;
    }


}



